<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Organizations</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Organizations</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">

      <!-- Default box -->
      <div class="card" id="organizations-add">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="saveOrgData"
          method="POST"
          novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{name: 'Organizations'}"><i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="org_name" class="control-label">Name</label>
              <input type="text" id="org_name" name="org_name" v-model="organization.org_name"
                     v-validate="'required'" class="form-control"
                     :class="{'has-error' : errors.has('org_name')}" placeholder="Name">
              <div class="help text-danger" v-show="errors.has('org_name')">
                {{ errors.first('org_name') }}
              </div>
            </div>
            <div class="form-group">
              <label for="org_logo">Logo</label>
              <div class="row">
                <div class="col-4">
                  <input type="file" name="org_logo" class="form-control-file" id="org_logo" @change="selectFile">
                </div>
                <div class="col-8">
                  <img alt="preview image" v-if="temp_org_logo" v-bind:src="temp_org_logo" width="100" height="100"
                       class="img-thumbnail"/>
                </div>
              </div>
            </div>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <button type="submit" v-if="is_edit === false" class="btn btn-primary">Submit</button>
            <button type="submit" v-if="is_edit === true" class="btn btn-primary">Update</button>
          </div>
        </form>
        <!-- /.card-footer-->
        <!--        <span><pre>@{{ $data.organization }}</pre> </span>-->
      </div>
      <!-- /.card -->


    </section>
  </div>
</template>
<script>

import { OrganizationsAPI} from "@/services/api";
export default {
  name: 'OrganizationForm',
  metaInfo: {
    title: "Organization | Dotlines"
  },
  data: () => ({
    list: [],
    params: '',
    organization: {},
    is_edit: false,
    temp_org_logo: '',
    is_loading: false,
  }),
  methods: {
    saveOrgData: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if(this.is_edit) {
            OrganizationsAPI.update(this.params.id, this.organization).then(response => {
              this.$router.push({name: 'Organizations'});
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error.data);
            });
          } else {
            OrganizationsAPI.store(this.organization).then(response => {
              this.$router.push({name: 'Organizations'});
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error.data);
            });
          }
        }
      });
    },
    getOrganizationDetail: function (id) {
      return new Promise(resolve => {
        let _this = this;
        _this.asset_get_url = process.env.VUE_APP_ASSET_UPLOAD_URL;
        OrganizationsAPI.show(id).then(response => {
          _this.organization = response;
          _this.temp_org_logo = _this.asset_get_url + '/' + _this.organization.org_logo;
          _this.$delete(_this.organization, 'org_logo');
          resolve('resolved');
          })
          .catch((error) => {
            resolve('rejected');
          });
      });
    },
    selectFile: function (event) {
      // `files` is always an array because the file input may be in multiple mode
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.organization.org_logo = reader.result;
        this.temp_org_logo = reader.result;
      };

    },
    async getOrgData() {
      let _this = this;
      _this.params = _this.$route.params;
      if (!_.isEmpty(_this.params) && _this.params.id !== undefined) {
        _this.is_edit = true;
      }
      _this.is_loading = true;
      if (_this.is_edit === true) {
        await this.getOrganizationDetail(_this.params.id);
      }
      _this.is_loading = false;
    },
  },
  mounted: function () {
    let _this = this;
    _this.getOrgData();
  }
}
</script>
